import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '../../base-page/base-page.component';
import { FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { take } from 'rxjs/operators';
import { Prize } from 'src/app/services/client-area/client-area.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TeaserTopMobileService } from 'src/app/services/teaser-top-mobile/teaser-top-mobile.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { bonusQueueTrx, bonusTrx, clientAreaTrx } from 'src/app/router-translation.labels';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { timer } from 'rxjs';

declare var confetti: any;
var count = 200;


@Component({
  template: ''
})
export class BonusLotteryWheelRouteComponent extends BasePageComponent {

  constructor(public clientAreaDialog: MatDialog,
  ) { super() }



  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.clientAreaDialog.open(BonusLotteryWheelComponent, {
      ...FULL_DIALOG_CONFIG,
      panelClass: 'lottery-wheel-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigate(["/"], { relativeTo: this.route, skipLocationChange: false })
    });
  }
}

@Component({
  selector: 'app-bonus-lottery-wheel',
  templateUrl: './bonus-lottery-wheel.component.html',
  styleUrls: ['./bonus-lottery-wheel.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate({{rotationAngle}}deg)' }), { params: { rotationAngle: '180' } }),
      transition('default => rotated', animate('2500ms ease-in-out')),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class BonusLotteryWheelComponent implements OnInit {

  constructor(
    // public dialogRef: MatDialogRef<GameClientAreaComponent>,
    private ngZone: NgZone,
    private bonusLotteryService: BonusLotteryService,
    private translationConfig: TranslationConfig,
    public deviceDetect: DetectDeviceService,
    private teserTopService: TeaserTopMobileService,
    public minimizeSiteService: MinimizeSiteService,
    private router: Router,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
  ) { }

  state: string = 'default';
  rotationAngle: number = 720;
  maximumField: number = 16;
  numberDegressRotation: number = this.rotationAngle;
  isShowWin: boolean = false;
  isStartWinAnimation: boolean = false;
  prize: Prize;
  isActive: boolean = true;
  leftToOpen: number = 0;
  isFinished: boolean = false;
  public bonusQueueUrl = this.translationConfig.getTranslation(clientAreaTrx) + '/' + this.translationConfig.getTranslation(bonusQueueTrx);
  prizeValue: string;
  prizeName: string;
  @ViewChild('confetticanvas') confettiCanvas: ElementRef;
  myConfetti: any;

  ngOnInit(): void {
    this.getWheelInfo();
  }

  getWheelInfo() {
    this.bonusLotteryService.getWheelInfo().pipe(take(1)).subscribe(info => {
      console.log(info)
      this.leftToOpen = info.leftToOpen;
      if (this.leftToOpen > 0) {
        this.isActive = true;
      }
      else {
        // this.teserTopService.disableActivePromoTeaser();
        this.isFinished = true;
        this.isActive = false;
      }
    })

  }



  spinWheel() {
    this.isStartWinAnimation = false;
    this.state = 'default';
    this._ngZone.runOutsideAngular(() => {
      this.myConfetti = confetti.create(this.confettiCanvas.nativeElement, {
        resize: true,
        useWorker: true
      });
    });


    this.numberDegressRotation = this.rotationAngle + 22.5 * Math.floor(Math.random() * (this.maximumField + 1));
    if (this.isActive) {
      this.isActive = false;
      this.state = 'rotated';
      // console.log("spin wheel active")

      this.bonusLotteryService.startLottery().pipe(take(1)).subscribe({
        next: prize => {
          this.bonusLotteryService.resetStore();
          this.getWheelInfo();
          this.prize = prize;
          const [first, ...rest] = prize.displayName.split(' ');
          this.prizeValue = first;
          this.prizeName = rest.join(' ')
        }, error: err => {
          this.getWheelInfo();
        }
      });
    }
  }

  animDone($event) {
    if (!!this.myConfetti) {
      timer(100).subscribe(() => {
        this.myConfetti({
          particleCount: 100,
          spread: 160,
          origin: { y: 0.7 }

        });
        this.myConfetti({
          pread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
          origin: { y: 0.7 }

        });
      });
    }


    if ($event.fromState === 'default' && $event.toState === 'rotated') {
      this.state = 'default';
      this.isStartWinAnimation = true;
    }

  }

  overlayClicked() {
    if (this.isShowWin) {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.minimizeSiteService.restorePage();
    this.router.navigate(["", clientAreaTrx, bonusQueueTrx], { skipLocationChange: false })

  }

}
