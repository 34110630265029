import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { HOST_NAME } from 'src/app/app-routing.module';
import { BaseFirebaseService } from '../../http/base-firebase.service';
import { FirestoreUserData } from '../firebase/firebase-auth.models';
import { LoggedStatus, UserInfo, UserState } from './login.models';

@Injectable({
  providedIn: 'root'
})
export class LoginStatusService extends BaseFirebaseService {

  private userInfo = new BehaviorSubject<UserInfo>({
    isLogged: LoggedStatus.voidState, isLoggedFirebase: LoggedStatus.voidState,

  });

  private userState = new BehaviorSubject<UserState>({
    activeBonusWarningDisplayed: false
  })
  private user

  private _partialLoginNotifier: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


  public getIfUserLogged(): Observable<UserInfo> {
    return this.userInfo.asObservable().pipe(
      filter(userInfo => userInfo.isLogged === LoggedStatus.logged),
      distinctUntilChanged()
    );
  }

  public getLoginPartialStatus(): Observable<boolean> {
    return this._partialLoginNotifier.asObservable().pipe(
      filter((resp) => resp !== null)
    );
  }

  public setLoginPartialStatus(status: boolean) {
    this._partialLoginNotifier.next(status);
  }

  public getLoginStatus(includeVoid = true): Observable<UserInfo> {
    return this.userInfo.asObservable().pipe(
      filter(status => includeVoid ? true : status.isLogged !== LoggedStatus.voidState),
      distinctUntilChanged()
    );
  }

  public getUserState(): Observable<UserState> {
    return this.userState.asObservable().pipe(
      distinctUntilChanged()
    );
  }

  constructor(
    afAuth: AngularFireAuth,
    http: HttpClient,
    afs: AngularFirestore,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  public updateUserInfo(params: any) {
    let current = this.userInfo.getValue();
    this.userInfo.next({ ...current, ...params });
  }

  public udpateUserState(key: string, value: any, saveDb: boolean = false) {
    let current = this.userState.getValue();
    current[key] = value;
    this.userState.next({ ...current });
    if (saveDb) {

      this.setDocument<FirestoreUserData>(current.dbUser, `prize-users/${this.userInfo.getValue().username}`, true)
    }
  }


}
