import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { HOST_NAME } from 'src/app/app-routing.module';
import { test_userDocumentsResponce, typeMapping, UserDocumentsResponse, DocumentData, Status, InputFile, TranslatedStatus } from './upload-documents.models';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';
import { DOWNLOAD_DOCUMENT, GET_DOCUMENT, UPLOAD_DOCUMENTS, USER_DOCUMENTS } from '../http/base-http.service';
import { BaseUsermgrService } from '../http/base-usermgr.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentsService extends BaseUsermgrService {

  private userDocumentsResponse = new BehaviorSubject<UserDocumentsResponse>(null);

  constructor(
    http: HttpClient,
    cookie: CookieService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
  ) {
    super(http, cookie, locale, hostName);

  }

  public updateUserDocuments(): void {
    this.getDocuments().pipe(
      tap((userDocumentsResponse) => this.userDocumentsResponse.next(userDocumentsResponse)),
      catchError(error => {
        console.error('Error fetching user documents', error);
        return of(null);
      })
    ).subscribe();
  }

  public getUserDocuments(): Observable<UserDocumentsResponse> {
    return this.userDocumentsResponse.asObservable();
  }

  public fillDocumentData(documentData: DocumentData, userDocumentsResponse: UserDocumentsResponse): DocumentData {
    const userDocumentData = { ...documentData };
    // userDocumentsResponce = test_userDocumentsResponce; //

    userDocumentData.sections.forEach(section => {
      const key = Object.keys(typeMapping).find(k => typeMapping[k] === section.type);
    
      if (key && userDocumentsResponse.status.hasOwnProperty(key)) {
        const { documents, status, hide_not_required: hide } = userDocumentsResponse;
    
        if (Array.isArray(documents[key])) {
          section.documents = documents[key].map(doc => ({
            ...doc,
            created: doc.created,
            name: doc.name,
            status: this.translateStatus(doc.status as Status),
            status_display: doc.status_display
          }));

        }

        section.disable = status[key] === Status.Approved || status[key] === Status.NotRequired /* || status[key] === Status.Pending */;
        section.status = this.translateStatus(status[key] as Status); /* section.status = status[key]; */
        section.hide = status[key] === Status.NotRequired;
        section.attempt = section.documents ? section.documents.length : 0;

      }
      
    });
    
    return userDocumentData;
  }

  createFileUploadHeaders(headers: {
    [name: string]: string | string[];
  } = {}): HttpHeaders {
    const newHeaders = {};
    Object.assign(newHeaders, headers);
    const csrfToken = this.cookie.get('csrftoken');
    newHeaders['Content-language'] = this.locale;
    newHeaders['Accept-Language'] = `pl-PL,${this.locale}`;
    newHeaders['x-translation-lang'] = this.locale?.slice(0, 2);
    if (csrfToken) {
      newHeaders['X-CSRFToken'] = csrfToken;
    }
    return new HttpHeaders(newHeaders);
  }

  uploadDocument(inputFile: InputFile) {
    const docForm = new FormData();

    const file = inputFile.file;

    docForm.append('document_type', inputFile.type);
    docForm.append('file-1', file);

    const httpOptions = {
      headers: this.createFileUploadHeaders(),
      withCredentials: true
    };

    //console.log(docForm.get('file-1'), docForm.get('document_type'));
    //return of(true)
    return this.http.post<any>(this.apiUrl + UPLOAD_DOCUMENTS, docForm, httpOptions);
  }

  getDocuments() {
    return this.get<any>(USER_DOCUMENTS);
  }

  getDocument(docId: string) {
    const url = GET_DOCUMENT + docId
    return this.get<any>(url)
  }

  downloadDocument(docId: string) {
    const url = DOWNLOAD_DOCUMENT + docId
    return this.get<any>(url)
  }

  // Function to map Status to TranslatedStatus
  translateStatus(status: Status): string {
    const translationMap: { [key in Status]: string } = {
      [Status.NotRequired]: TranslatedStatus.NotRequired,
      [Status.Required]: TranslatedStatus.Required,
      [Status.Approved]: TranslatedStatus.Approved,
      [Status.Pending ]: TranslatedStatus.Pending,
      [Status.Rejected]: TranslatedStatus.Rejected,
    };

    return translationMap[status];
  }

}
