<div class="title-wrap">
  <app-common-page-title icon="assets/icons/icon-profile-header.svg"><ng-container i18n>Profile</ng-container></app-common-page-title>
</div>
<div class="profile-info">
  <!-- <div fxLayoutAlign="start center" fxLayout="row" class="title-wrap">
    <app-header-page-compoanent iconName="icon-profile-header.svg">Profile </app-header-page-compoanent>

  </div> -->
  <div class="content">
    <div class="profile-wrapper desktop" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div fxFlex class="top-container">
        <div class="user-details" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <ng-container *ngIf="avatar">
            <div class="vip-avatar" *ngIf="userRole else regularAvatar">
              <div class="vip-img-content">
                <img class="vip-player-img" src="{{avatar}}" />
                <div class="role" i18n>vip</div>
              </div>
              <img class="vip-leef" src="{{licenceService.getKey('icon-vip-leef')}}" >
            </div>
            <ng-template #regularAvatar>
              <img class="regular-player-img" src="{{avatar}}" />
            </ng-template>
          </ng-container>
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px">
            <div *ngIf="verified" class="verified" i18n>Verified</div>
            <div class="email" i18n>{{email}}</div>
          </div>
        </div>
      </div>
      <div fxFlex class="level-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <div class="level-container-img-box">
          <div class="image" [ngStyle]="{'background': userRole ? 'var(--image-border-gradient)' : 'var(--image-border)'}">
            <img *ngIf="lemonIconNameIndex" src="assets/icons/lemons-levels/icon-lemon-level-{{lemonIconNameIndex}}.svg" alt="icon-gamification" />
            <div *ngIf="playerLoyalityName" class="label" [ngClass]="{'vip-label':userRole}">{{playerLoyalityName}}</div>
          </div>
          <div class="label-level" i18n>{{playerLevelPosition}}</div>
        </div>
        <div class="level-progress-container" fxLayout="column" fxLayoutGap="8px">
          <div class="level-progress-number" fxLayout="row" fxLayoutAlign="space-around center">
            <div class="number"  *ngFor="let levelValue of playerCurrentLevelList">{{levelValue}}</div>
          </div>
          <div class="profile-level-progress">
            <mat-progress-bar color="accent" mode="determinate" [value]="gamificationPercentFill" [ngClass]="gamificationPercentFill===100 ? 'value-comleted' : 'value-not-comleted'">
            </mat-progress-bar>
            <div class="level-divider-container" fxLayout="row" fxLayoutAlign="space-evenly center">
              <div class="level-divider"></div>
              <div class="level-divider"></div>
              <div class="level-divider"></div>
              <div class="level-divider"></div>
            </div>
          </div>
        </div>
        <div class="level-container-img-box">
          <div class="image">
            <img *ngIf="lemonNextLevelIconNameIndex" src="assets/icons/lemons-levels/icon-lemon-level-{{lemonNextLevelIconNameIndex}}.svg" alt="icon-gamification" />
            <div *ngIf="playerNextLoyalityName" class="label" [ngClass]="{'vip-label':userRole}">{{playerNextLoyalityName}}</div>
          </div>
          <div class="label-level" i18n>{{playerNextLevelPosition}}</div>
        </div>
      </div>
    </div>

    <div class="items" [ngStyle]="{'grid-template-columns':cashBackTwoActive ? '1fr 1fr' : '1fr 1fr 1fr'}">
      <div class="item-wrapper light">
        <div class="item">
          <div class="spinner-container">
            <div class="spinner-background"  [ngClass]="{'spinner-background-active':isPrizeBoxActive && showGamification}">
              <img *ngIf="isPrizeBoxActive && showGamification" src="assets/client-area/icon-box-on-80x80.png" alt="icon-gamification" />
              <img *ngIf="!isPrizeBoxActive && showGamification" src="assets/client-area/icon-box-off-80x80.png" alt="icon-gamification" />
            </div>
            <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="64" style="stroke-linecap: round;">
            </mat-progress-spinner>
          </div>
          <div class="text-container">
            <div class="title" i18n>Treasure Boxes</div>
            <div *ngIf="isPrizeBoxActive" i18n>Collect your reward!</div>
            <div *ngIf="!isPrizeBoxActive" style="color: #DADADA;" i18n>Level {{gamificationLevel}} completed in {{gamificationPercentFill.toFixed(2)}}%</div>
          </div>

          <div class="info">
            <a [routerLink]="treasureBoxInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg" />
            </a>
          </div>
        </div>
        <button round-button filled="filled" color="yellow" (click)="openPrizeBox()" [disabled]="!isPrizeBoxActive" i18n>Open!</button>
      </div>

      <div class="item-wrapper light">
        <div class="item">
          <div class="spinner-container">
            <div class="spinner-background" [ngClass]="{'spinner-background-active':!!userChargebackToClaim && showGamification}">
              <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-off-80x80.png" alt="icon-gamification" />
              <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-on-80x80.png" alt="icon-gamification" />
            </div>
            <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="64" style="stroke-linecap: round;">
            </mat-progress-spinner>
            <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="64" style="stroke-linecap: round;">
            </mat-progress-spinner>
          </div>
          <div class="text-container">
            <div i18n>Cashback 1 earned:</div>
            <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
            <div *ngIf="!userChargebackToClaim" style="color: #DADADA;"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
          </div>
          <div class="info">
            <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="filled" [disabled]="!userChargebackToClaim" color="warn" (click)="claimCashback()" i18n>Claim!</button>
      </div>

      <div *ngIf="cashBackTwoActive" class="item-wrapper light">
        <div class="item">
          <div class="spinner-container">
            <div class="spinner-background" [ngClass]="{'spinner-background-active':!!userChargebackToClaim && showGamification}">
              <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-off.png" alt="icon-gamification" />
              <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-on.png" alt="icon-gamification" />
            </div>
            <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="64" style="stroke-linecap: round;">
            </mat-progress-spinner>
            <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="64" style="stroke-linecap: round;">
            </mat-progress-spinner>
          </div>
          <div class="text-container">
            <div i18n>Cashback 2 earned:</div>
            <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
            <div *ngIf="!userChargebackToClaim" style="color: #DADADA;"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
          </div>
          <div class="info">
            <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="filled" [disabled]="!userChargebackToClaim" color="warn" (click)="claimCashback()" i18n>Claim!</button>
      </div>

      <div class="item-wrapper light" [ngStyle]="{'grid-area': cashBackTwoActive ? '1/2' : 'auto'}">
        <div class="item">
          <div class="icon-container">
            <img *ngIf="userBonusLotteryResponse" src="assets/client-area/icon-wheel-on.png" />
            <img *ngIf="!userBonusLotteryResponse" src="assets/client-area/icon-wheel-ff.png" />
          </div>
          <div class="text-container">
            <div class="title" i18n>Lemon Lottery</div>
            <div *ngIf="userBonusLotteryResponse" i18n>Claim your free ticket!</div>
            <div *ngIf="!userBonusLotteryResponse" style="color: #DADADA;" i18n>No active lottery</div>
          </div>
          <div class="info">
            <a [routerLink]="lotteryInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="filled" [disabled]="!userBonusLotteryResponse" color="yellow" (click)="openLotteryWheel()" i18n>Spin!</button>
      </div>

    </div>

    <div class="items-dark">
      <div class="item-wrapper-dark">
        <div class="item">
          <div class="icon-container-dark">
            <img src="assets/client-area/icon-mybonuses-box.png" />
          </div>
          <div class="text-container">
            <div class="title" i18n>My Bonuses</div>
            <div i18n>Manage your bonuses</div>
          </div>
          <div class="info">
            <a [routerLink]="bonusesUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="empty" color="warn" i18n [routerLink]="bonusesUrl" routerLinkActive="active">Proceed</button>
      </div>
      <div class="item-wrapper-dark">
        <div class="item">
          <div class="icon-container-dark">
            <img src="assets/client-area/mygame-new-img.svg" />
          </div>
          <div class="text-container">
            <div class="title" i18n>My games</div>
            <div i18n>Manage your collection</div>
          </div>
          <div class="info">
            <a [routerLink]="myGamesUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="empty" color="warn" i18n [routerLink]="myGamesUrl" routerLinkActive="active">Proceed</button>
      </div>
    </div>

    <div class="divider">
      <mat-divider></mat-divider>
    </div>

    <div class="main-wrap">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="edit-profile-wrapper wrapper">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <img src="assets/client-area/icon-menu-edit-profile.svg">
            <div class="title" i18n>Edit profile</div>
          </div>
          <div class="description" i18n>Edit your profile picture and other data</div>
        </div>
        <button round-button class="capitalize-button button" filled="empty" color="warn" [routerLink]="editProfileUrl" routerLinkActive="active" i18n>Proceed</button>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="edit-profile-wrapper wrapper">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
            <img src="assets/client-area/icon-menu-change%20password.svg">
            <div class="title" i18n>Change password</div>
          </div>
          <div class="description" i18n>Manage security of your account</div>
        </div>
        <button round-button class="capitalize-button button" filled="empty" color="warn" [routerLink]="changePasswordUrl" routerLinkActive="active" i18n>Proceed</button>
      </div>
    </div>
  </div>
</div>