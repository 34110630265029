import { Component } from '@angular/core';
import { LastPlayedService } from '../../../services/profile/last-played.service';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from '../../base-page/base-page.component';
import { take, takeUntil } from 'rxjs/operators';
import { myGamesTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { combineLatest } from 'rxjs';
import { FavouritesService } from 'src/app/services/profile/favourites.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';

@Component({
  selector: 'app-my-games-list',
  templateUrl: './my-games-list.component.html',
  styleUrls: ['./my-games-list.component.scss']
})
export class MyGamesListComponent extends BasePageComponent {

  constructor(
    public lastPlayedService: LastPlayedService,
    public favoriteService: FavouritesService,
    protected loginStatusService: LoginStatusService,
    public detectDesktop: DetectDeviceService

  ) { super() }

  categoryName: string = $localize`:@@myGamesCategory:my games`;
  linkUrl: string = myGamesTrx;

  games: GameItem[] = [];
  isLoading: boolean = true;

  ngOnInit(): void {
    const favourites$ = this.favoriteService.getFavouriteGames();
    const lastPlayed$ = this.lastPlayedService.getLastPlayed();
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      combineLatest([favourites$, lastPlayed$]).pipe(takeUntil(this.unsubscribe)).subscribe(
        ([favourites, lastPlayed]) => {
          let games = [...favourites, ...lastPlayed];
          // remove duplicates from games
          games = games.filter((item, index) => {
            return games.findIndex((game) => game.gameid === item.gameid) === index;
          });
          if (this.detectDesktop.isDesktop()) {
            this.games = games?.slice(0, 4);
          } else {
            this.games = games?.slice(0, 3);
          }
          this.isLoading = false;
        });
    })
  }
}
