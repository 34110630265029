import { Component, OnInit } from '@angular/core';
import { GameCategoryDialogComponent } from '../game-category-dialog/game-category-dialog.component';
import { SwiperOptions } from 'swiper';
import { GameCategory } from 'src/app/apollo/models/base-models';

@Component({
  selector: 'app-game-category-swiper',
  templateUrl: './game-category-swiper.component.html',
  styleUrls: ['./game-category-swiper.component.scss']
})
export class GameCategorySwiperComponent extends GameCategoryDialogComponent {

  mobileConfig: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 8,
    pagination: { clickable: true },
    navigation: true,
    slidesOffsetBefore: 10,
    slidesOffsetAfter: 10,
    breakpoints: {
      '@0.00' : {
        slidesPerView:4.5,
      },
      '@0.60' : {
        slidesPerView:5.5,
      },
      '@0.75' : {
        slidesPerView:7.5,
      },
      '@1.00' : {
        slidesPerView:8.5,
      },
    }
  };
}
