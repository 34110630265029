import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { FreeSpinListService } from 'src/app/services/wallet/freespins.service';
import { ActivePrizes } from 'src/app/services/wallet/wallet.models';
import { BasePageComponent } from '../../base-page/base-page.component';
import { DESKTOP_SMALL, DialogConfig, SMALL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { Apollo, gql } from 'apollo-angular';
import { GameItem } from 'src/app/apollo/models/base-models';
import { combineLatest, of } from 'rxjs';
import { log } from 'console';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { gameInfoTrx } from 'src/app/router-translation.labels';

const query = gql`query GameSearchList($searchNames: [String!]){
  games(where: {gameid_in: $searchNames}) {
    id
    name,
    systemName,
    gameid,
    thumbnail {
      url
    }
  }
  }
  `

@Component({
  selector: 'app-active-prizes',
  templateUrl: './active-prizes.component.html',
  styleUrls: ['./active-prizes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivePrizesComponent extends BasePageComponent {

  constructor(
    private apollo: Apollo,
    private router: Router,
    private freeSpinsService: FreeSpinListService,
    private detectDesktopService: DetectDeviceService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    protected translationConfig: TranslationConfig
  ) { super() }

  activePrizes: ActivePrizes[];
  prizeList: ActivePrizes[];
  games: GameItem[];
  dialogSize: DialogConfig = SMALL_DIALOG_CONFIG;
  isDesktop: boolean = this.detectDesktopService.isDesktop();
  public displayedColumnsFreeSpinsActive: string[] = ['exp-date', 'freespins', 'games'];


  ngOnInit(): void {
    this.freeSpinsService.requestFreeSpinsActive().pipe(
      takeUntil(this.unsubscribe),
      mergeMap(resp => {
        const gameIds = resp.reduce((accumulator, prize) => {
          return accumulator.concat(prize.games.map(item => item.id.toString()));
        }, []);

        const uniqueGameIds = [...new Set(gameIds)];

        return combineLatest(this.apollo
          .watchQuery<{ games: GameItem[] }>({
            query: query,
            variables: {
              searchNames: uniqueGameIds
            }
          })
          .valueChanges, of(resp))

      }),
      take(1)
    ).subscribe(([cmsResp, resp]) => {
      this.activePrizes = resp;
      this.games = cmsResp.data.games;
      this.changeDetectorRef.detectChanges();
    })

    // this.freeSpinsService.getFreeSpinsList().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
    //   this.prizeList = resp;
    //   this.changeDetectorRef.detectChanges();
    // })

    if (this.detectDesktopService.isDesktop()) {
      this.dialogSize = DESKTOP_SMALL
    }
  }

  public static mapGameName(game: string) {
    return game.toLowerCase().replace(/\s/g, '-').replace(/[^a-zA-Z0-9,;\-.!? ]/g, '').replace("'", '')
  }

  public openGame(index: number, gameId: number) {
    const systemName = this.games.find(gameItem => gameItem.gameid === gameId.toString()).systemName;
    if (systemName) {
      this.router.navigate(["", this.translationConfig.getTranslation(gameInfoTrx), systemName]);
    }
  }

  getThumbnailUrl(gameId: number): string {
    const gameItem = this.games.find(gameItem => gameItem.gameid === gameId.toString());
    console.log('active game img', gameItem);
    if (gameItem) {
      return gameItem.thumbnail?.url;
    }
    return '';
  }
}
