import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { LoggedStatus, LoginEmailResponse, UserInfo } from './login.models';
import { LOGIN_EMAIL_URL } from '../../http/base-http.service';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginStatusService } from './login-status.service';
import { UserInfoService } from '../check-session/user-info.service';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class LoginEmailService extends BaseUsermgrService {




  constructor(http: HttpClient, cookie: CookieService, private loginStatusService: LoginStatusService,
    private userInfoLoginService: UserInfoService,
    @Inject(LOCALE_ID) public locale: string,
    private faService: FirebaseAuthService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, cookie, locale, hostName);
  }



  requestLoginUser(loginForm: UntypedFormGroup): Observable<UserInfo> {

    return super.postRequest<LoginEmailResponse>(LOGIN_EMAIL_URL, loginForm)
      .pipe(
        // switchMap((resp) => {
        //   this.loginStatusService.updateUserInfo({
        //     isLogged: resp.status == "success" ? LoggedStatus.logged : LoggedStatus.notLogged,
        //     username: resp.user ? resp.user : null,
        //   });
        //   return this.loginStatusService.getLoginStatus();
        // }),
        switchMap((userResp) => {
          return this.faService.signWithToken(userResp.firebase_token)
        }),
        switchMap((user) => {
          user => {
            if (user) {
              // this.faService.updateUserData(user);
            } else {
              this.loginStatusService.updateUserInfo({
                isLogged: LoggedStatus.notLogged,
              });
            }
          }
          return this.userInfoLoginService.requestCheckUserInfo()
        })
      );
  }
}
