<!-- <div class="dialog-wrapper" fxLayoutAlign="center center" fxLayout="row" *ngIf="dialogData.msg">
    <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
        <h2 i18n>Confirmation</h2>
        <div>
        <p [innerHTML]="dialogData.msg"></p>
        </div>
        <div class="footer" fxLayout="column" fxLayoutAlign="center center">
            <div class="buttons">
                <button round-button color="warn" filled="filled" (click)="confirm()" type='button' i18n>confirm</button>
                <button round-button color="accent" filled="empty" (click)="closeDialog()" type='button' i18n>close dialog</button>
            </div>
        </div>
    </div>
</div> -->

<div class="dialog-wrapper">
    <div class="dialog-header">
        <h2 i18n>Confirmation</h2>

        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog-body">
        <ng-container *ngIf="!dialogData.msg else dialogDataMsg">

        </ng-container>

        <ng-template #dialogDataMsg>
            <p style="width: 100%; text-align: left;" [innerHTML]="dialogData.msg"></p>
        </ng-template>
    </div>

    <div class="dialog-footer">
        <div class="buttons">
            <button round-button color="primary" (click)="closeDialog()" type='button'>CANCEL</button>
            <button round-button color="primary" filled="filled" (click)="confirm()" type='button'>CONFIRM</button>
        </div>
    </div>
</div>