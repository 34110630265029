import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserBonusLottery } from './profile.models';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { OPEN_LOTTERY_BOX, SPIN_WHEEL, USER_BONUSLOTTERY, WHEEL_INFO } from '../http/base-http.service';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { Prize, PrizeBox, WheelLotteryinfo } from '../client-area/client-area.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'any'
})
export class BonusLotteryService extends BaseFirebaseService {

  wallet: Observable<any>;
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) { super(http, afAuth, afs, hostName); }

  private store = new BehaviorSubject<UserBonusLottery>(null);

  public getStore() {
    return this.store.asObservable().pipe(
      filter(item => item !== null),
    )
  }

  public isActiveLottery(): Observable<boolean> {
    return this.getStore().pipe(
      map(item => item === null || item.isActive)
    )
  }

  public resetStore() {
    this.store.next(null);
  }

  public getBonusLottery(): Observable<UserBonusLottery> {
    return this.loginStatusService.getLoginStatus().pipe(
      filter(user => user.isLogged === LoggedStatus.logged),
      concatMap(user => {
        const backendCaller$: Observable<UserBonusLottery> = this.get<UserBonusLottery>(USER_BONUSLOTTERY);
        return backendCaller$.pipe(
          map((resp: UserBonusLottery) => {
            if (resp) {
              resp.prizeBoxes.forEach((elem, index) => {
                elem.index = index;
              })
              return resp;
            } else {
              return {
                isActive: false,
                prizeBoxes: [],
                uid: '',
                leftToOpen: 0
              };
            }
          }),
          switchMap((resp) => {
            this.store.next(resp)
            return of(resp)
          }),
        )
      })
    )
  }

  public openLotteryBox(item: PrizeBox) {
    return this.loginStatusService.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      mergeMap(
        (status) => {
          return this.get<Prize>(OPEN_LOTTERY_BOX + `/${item.index}`);
        }
      ),
    )
  }

  public getWheelInfo() {
    return this.loginStatusService.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      mergeMap(
        (status) => {
          return this.get<WheelLotteryinfo>(WHEEL_INFO);
        }
      ),
    )
  }

  public startLottery() {
    return this.loginStatusService.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      mergeMap(
        (status) => {
          return this.get<Prize>(SPIN_WHEEL);
        }
      ),
    )
  }

}
