<div fxLayoutAlign="center center" *ngIf="!detectDeviceService.isDesktop() else desktop">
  <div class="game-card">
    <!-- <img (click)="openGameDialog()" src="{{ data.thumbnail.url }}" /> -->
    <a [routerLink]="launchGameUrl" (click)="launchGame($event,gameNameSlug)">
      <div class="thumbnail"
           [style.background-color]="data.initialLoadColor ? data.initialLoadColor.css : 'rgb(28 146 62 / 59%)'">

        <img width="121px" height="121px" alt="{{data.name}}" data-src="{{ data.thumbnail?.url }}" loading=lazy
             class="swiper-lazy"/>

        <!-- <img *ngIf="data.thumbnailDesktopSquare else oldthumb" loading=lazy alt="{{data.name}}" src="{{ data.thumbnailDesktopSquare?.url }}" /> -->
        <div class="badge" [ngSwitch]="badge()">
          <ng-container *ngSwitchCase="'big_win'">
            <img class="big-win badge-tag" src="assets/icons/badge-bigwin-mobile.svg">
          </ng-container>
          <ng-container *ngSwitchCase="'hot'">
            <img class="badge-tag" src="assets/icons/badge-hot-mobile.svg">
          </ng-container>
          <ng-container *ngSwitchCase="'cash_drops'">
            <img class="cash-drops" src="assets/icons/tag-thumbnail-mobile-kalamba.png">
          </ng-container>


          <ng-container *ngSwitchCase="'drops_win'">
            <img src="assets/icons/drops-win-icon.png">
          </ng-container>

          <ng-container *ngSwitchCase="'take_prize'">
            <img src="assets/icons/take-the-prize-mobile.png">
          </ng-container>

          <ng-container *ngSwitchDefault>

          </ng-container>
        </div>
        <div *ngIf="data.tag?.includes('exclusive')" class="exclusive">
          <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
        </div>
        <div *ngIf="data.tag?.includes('power_weekends')" class="power-weekends">
          <img src="assets/icons/tag-power-weekends-pragmatic-mobile-2x.png">
        </div>
        <div *ngIf="data?.tag?.includes('holiday_spin_splash')" class="holiday-spin-splash">
          <img src="assets/icons/holiday-spin-splash-mobile-tag-2x.png">
        </div>
        <div *ngIf="data.uniquePlayers" fxLayout="row" fxLayoutAlign="center center" class="unique-players">
          <img src="assets/icons/icon-profile-sticky.svg" loading=lazy alt="signup" class="profile-icon"/>
          <span>{{ data.uniquePlayers }}</span>
        </div>
        <ng-template #oldthumb>
          <img width="121px" height="121px" loading=lazy alt="{{data.name}}" src="{{ data.thumbnail?.url }}"/>
        </ng-template>
      </div>
    </a>
    <div class="title">
      {{ data.name }}
    </div>
  </div>
</div>

<ng-template #desktop>
  <div class="desktop">
    <div fxLayoutAlign="start" class="game-card-wrapper">
      <div class="game-card desktop" [ngClass]="'Square'">
        <!-- <img (click)="openGameDialog()" src="{{ data.thumbnail.url }}" /> -->
        <div class="thumbnail"
             [style.background-color]="data.initialLoadColor ? data.initialLoadColor.css : 'rgb(28 146 62 / 59%)'">
          <a [routerLink]="launchGameUrl" (click)="launchGame($event,gameNameSlug)">
            <div class="play-effect">
              <img src="assets/desktop/play-button-overlay.svg" alt="play-button-overlay">
            </div>
            <ng-container>
              <img *ngIf="data?.thumbnailDesktopSquare" loading=lazy alt="{{data.name}}"
                   src="{{ data.thumbnailDesktopSquare.url }}"/>
            </ng-container>
          </a>
        </div>
        <div class="title">
          {{ data.name }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
