<div class="filter-chips-wrapper">
    <ng-container *ngFor="let chip of chipsList">
      <mat-chip [ngClass]="{'clear-chip': chip.type === ChipType.CLEAR}" (click)="onChipRemove(chip)" [innerHTML]="chip.displayName | removehtml">
        <!-- <mat-icon matChipRemove (click)="onChipRemove(chip)">cancel</mat-icon> -->
        <!-- own chip remove -->
        <ng-container *ngIf="!(chip.type === ChipType.CLEAR)">
          <div class="icon-container">
            <img src="assets/desktop/close-icon.svg" alt="close-btn" />
          </div>
        </ng-container>
      </mat-chip>
    </ng-container>
</div>