<div #gamedialogwrapper *ngIf="!!data && data!= null else notfound" fxLayoutAlign="start center" fxLayout="column" class="game-dialog-wrapper">
  <div class="top-container" fxLayoutAlign="start center" fxLayout="column">
    <div fxLayoutAlign="center center" fxLayout="column" class="title-right-tile" style="width: 100%">
      <div class="game-info">
        <h1 class="game-title" mat-dialog-title>{{data.name}}</h1>
        <h2 class="game-provider" mat-dialog-title>{{data.provider}}</h2>
      </div>
    </div>
    <app-game-favourites-toggle [gameId]="data.gameid" class="fav-button"></app-game-favourites-toggle>
    <div class="thumbnail">
      <ng-template #imgThumbnail>
        <img *ngIf="data.thumbnailDesktopSquare else oldimage" src="{{ data.thumbnailDesktopSquare?.url }}" alt="{{ data.name }}" />
        <ng-template #oldimage>
          <img src="{{ data.thumbnail?.url }}" alt="{{ data.name }}" />
        </ng-template>
      </ng-template>

      <div *ngIf="animated && !isServer else imgThumbnail" fxLayoutAlign="center start">
        <div [ngStyle]="{ 'height.px': canvasHeight }" [style.display]="animationReady ? 'initial' : 'none'" #pixiAnimation></div>
        <div [style.display]="animationReady ? 'none' : 'initial'">
          <ng-container *ngIf="!animationReady; then imgThumbnail"></ng-container>
        </div>
      </div>

      <div *ngFor="let tag of data?.tag" [ngClass]="tag === 'exclusive' ? 'exclusive' : tag === 'power_weekends' ? 'power_weekends' : 'badge'" [ngSwitch]="tag" class="tags">
        <ng-container *ngSwitchCase="'drops_win'">
          <img src="assets/icons/drops-win-icon.png">
        </ng-container>
        <ng-container *ngSwitchCase="'cash_drops'">
          <img src="assets/icons/tag-thumbnail-mobile-kalamba.png">
        </ng-container>

        <ng-container *ngSwitchCase="'take_prize'">
          <img src="assets/icons/take-the-prize-mobile.png">
        </ng-container>

        <ng-container *ngSwitchCase="'exclusive'">
          <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
        </ng-container>
        <ng-container *ngSwitchCase="'holiday_spin_splash'">
          <img src="assets/icons/holiday-spin-splash-mobile-tag-2x.png">
        </ng-container>

        <ng-container *ngSwitchCase="'powe_-weekends'" class="power-weekends">
          <img src="assets/icons/tag-power-weekends-pragmatic-mobile-2x.png">
        </ng-container>


        <ng-container *ngSwitchDefault></ng-container>
      </div>
    </div>
    <div *loggedHide class="try-button"><button round-button color="warn" filled="empty" class="button" color="blackbackground" (click)="launchGame()" i18n>try game</button>
    </div>
    <div *loggedRender class="play-button"><button class="button" round-button color="warn" filled="empty" (click)="launchGame()" i18n>play game</button></div>
    <div class="buttons">
      <div *loggedRender fxLayoutAlign="space-between start" fxLayout="row">
        <app-balance-user [isGetFromStore]="true" [textCenterStyle]="true" (click)="closeDialog()"></app-balance-user>
      </div>
      <div *loggedHide><button class="button" round-button color="accent" filled="filled" (click)="signUp()" i18n>sign up</button></div>
    </div>
  </div>

  <div>
    <div *loggedHide><button class="button" style="width: 274px;margin: 32px 0;" round-button color="warn" filled="filled" color="primary" (click)="login()" i18n>Log in</button></div>
    <div *ngIf="data.shortDescription" class="description" [innerHTML]="data.shortDescription.safe">
    </div>
  </div>
</div>
<ng-template #notfound>
  <app-mobile-not-found></app-mobile-not-found>
</ng-template>
<app-close-dialog-button customClass="white" (closeEvent)="closeDialogReturn()"></app-close-dialog-button>
