import { gql } from 'apollo-angular';

export const SEARCH_FILTERS_QUERY = gql`
query SearchFilters($locale: Locale!, $countryName: [Countries!]) { 
  categories: pagesCategories (
    where: {AND: {regionName: front, country_contains_some: $countryName}},
    locales: [$locale]
  ) {
    id
    name
    
    data: categories {
      id
      name
      displayName
    }
  }
  
  operators: pagesCategories (
    where: {AND: {regionName: front_operators, country_contains_some: $countryName}}
    locales: [$locale]
  ) {
    id
    name
    
    data: categories {
      id
      name
      displayName
    }
  }
}
`

export const SEARCH_GAME_QUERY = gql`
query SearchGame($locale: Locale!, $operatorsIn: [String]!, $categoriesIn: [String]!, $nameIn: String) {
  games(
    where: {
      AND: [
        { gameCategory_some: { name_in: $operatorsIn } }
        { gameCategory_some: { name_in: $categoriesIn }}]
    	name_contains: $nameIn
    }
    locales: [$locale, en], first: 50
  ) {
    id
    gameid
    name
    licence  
    systemName
    
    thumbnail {
      url(transformation: {
        image: { resize: { width: 206, height: 206, fit: clip } }
      })
    }

    gameProvider {
      id
      name
    }
    gameCategory {
      id
      name
      displayName
    }
  }
}
`