import { Component } from '@angular/core';
import { ChargebackService } from '../../../../services/profile/chargeback.service';
import { TranslationConfig } from '../../../../utils/translate-config';
import { ToggleMenuBackService } from '../../../../services/utils/toggle-menu-back.service';
import { LoaderService } from '../../../../services/utils/loader/loader.service';
import { BestGamesService } from '../../../../services/client-area/best-games.service';
import { DetectDeviceService } from '../../../../services/utils/detect-device.service';
import { LoginStatusService } from '../../../../services/auth/login/login-status.service';
import { AvatarService } from '../../../../services/profile/avatar.service';
import { GamificationLevelService } from '../../../../services/profile/gamification-level.service';
import { PrizeBoxService } from '../../../../services/client-area/prize-box.service';
import { BonusLotteryService } from '../../../../services/profile/bonuslottery.service';
import { Router } from '@angular/router';
import { BaseMainClientAreaComponent } from '../base/base-client-area.component';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-desktop-main-client-area',
  templateUrl: './desktop-main-client-area.component.html',
  styleUrls: ['./desktop-main-client-area.component.scss']
})
export class DesktopMainClientAreaComponent extends BaseMainClientAreaComponent {

  playerCurrentLevelList: any[] = [];
  cashBackTwoActive:boolean= false;

  constructor(
    protected licenceService: LicenceService,
    chargebackService: ChargebackService,
    translationConfig: TranslationConfig,
    toggleBackService: ToggleMenuBackService,
    loaderService: LoaderService,
    bestGamesService: BestGamesService,
    deviceDetectService: DetectDeviceService,
    loginStatusService: LoginStatusService,
    avatarService: AvatarService,
    gamificationLevelService: GamificationLevelService,
    prizeBoxService: PrizeBoxService,
    bonusLotteryService: BonusLotteryService,
    router: Router) {
    super(chargebackService, translationConfig, toggleBackService, loaderService, bestGamesService, deviceDetectService, loginStatusService, avatarService, gamificationLevelService, prizeBoxService, bonusLotteryService, router);
    for (let i = 1; i <= 5; i++) {
      this.playerCurrentLevelList.push(i + (this.playerLoyalityNameIndex * 5));
    }
  }
}
